import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

import Logo from "./logo.js"

// import { useStaticQuery, graphql } from 'gatsby'

const Header = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     wpgraphql {
  //       generalSettings {
  //         title
  //         url
  //       }
  //       menu(id: "bmF2X21lbnU6Mg==") {
  //         menuItems {
  //           nodes {
  //             id
  //             url
  //             label
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  // const { url } = data.wpgraphql.generalSettings;
  // const items = data.wpgraphql.menu.menuItems.nodes.map(item => ({
  //   ...item,
  //   url: item.url.replace(url, '')
  // }));

  // {/* {items.map(item => (
  //   <li>
  //     <Link to={item.url} key={item.id}>
  //       {item.label}
  //     </Link>
  //   </li>
  // ))} */}
  return(
    <header className='header-wrapper'>
      <div className="header-content-wrapper">
        <div className="top-logo-wrapper">
          <a href="/">
            <Logo />
          </a>
        </div>
        <div className="menu-items-wrapper">
          <div className="menu-wrapper">
            <ul id="menu-top_menu" className="nav-wrapper">
              <li>
                <Link to="/">홈</Link> <br />
              </li>
              <li>
                <Link to="/about/">인사말</Link> <br />
              </li>
            </ul>
          </div>
          {/* <div className="cta-wrapper">
            <a>
              Book Now
            </a>
          </div> */}
        </div>
      </div>
    </header>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
