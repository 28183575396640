import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Logo = () => {
  const data = useStaticQuery(graphql`
    query Logo {
      file(relativePath: {eq: "penn_logo.png"}) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const image = getImage(data.file)

  return (
    <GatsbyImage 
      className="top-logo"
      image={image} 
      alt="펜실베니아 치과 로고"
    />)
}

export default Logo
