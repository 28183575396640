/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'
import { Helmet } from 'react-helmet'
// import data from "../../site/settings/settings.json"

import Header from "./header"
import Footer from "./footer"
import "../style/app.scss"

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata()

  return (
    <div>
      <Helmet>
        <html lang="ko" />
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* Naver Site Verification meta tag */}
        <meta name="naver-site-verification" content="66421864bb49c1971119f1c4f5908579a178c90e" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        {/* Libre Baskerville */}
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap" rel="stylesheet"></link>
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
        <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
        <script src="https://dapi.kakao.com/v2/maps/sdk.js?appkey=de3c4d0e58a9e7756492855b8847e8e1&libraries=services,clusterer,drawing" type="text/javascript" />
      </Helmet>
      {/* <div className="top-banner">
        상담문의: {data.contact_info.phone}
      </div> */}
      <Header siteTitle={title} />
      <div>
        <main>{children}</main>
        <Footer />
        {/* <footer>
          © {new Date().getFullYear()},
          {` `}
          <a href="https://www.gatsbyjs.org">Penn Dental</a>
        </footer> */}
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
