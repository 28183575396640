import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import officeSchedule from "../../site/settings/office_schedule.json"
import contactInfo from "../../site/settings/contact_info.json"

import Logo from "./logo.js"

const Footer = () => {

return (
  <footer>
    <div className="footer-top-wrapper">
      <div className="footer-logo-wrapper">
        <Link href="/">
          <Logo />
        </Link>
      </div>
      <div className="footer-info-wrapper">
        <div className="footer-office-hour-wrapper">
          <h4 className="footer-title--top">진료시간</h4>
          <div className="footer-office-hour ul-wrapper">
            <ul>{officeSchedule.office_hrs.line_1}</ul>
            <ul>{officeSchedule.office_hrs.line_2}</ul>
            <ul>{officeSchedule.office_hrs.line_3}</ul>
            <ul>{officeSchedule.office_hrs.line_4}</ul>
            <ul>{officeSchedule.office_hrs.line_5}</ul>
          </div>
        </div>
        <div className="address-wrapper">
          <h4 className="footer-title">주소</h4>
          <div className="footer-address ul-wrapper">
            <ul>
              {contactInfo.address.street}
              {/* 경기도 구리시 경춘로 253  */}
              <br />
              {contactInfo.address.street_2}
              {/* (인창동, 다우스퀘어 빌딩) 7층 */}
              <br />
              {contactInfo.address.postal_code}
            </ul>
            <ul>대표전화: {contactInfo.phone}{/* 031-552-2822 */}</ul>
          </div>
        </div>
        <div className="site-map-wrapper ul-wrapper">
          <h4 className="footer-title">사이트 맵</h4>
          <li><a href="/">홈</a></li>
          <li><a href="/about">인사말</a></li>
        </div>
      </div>
    </div>
    {/*<!-- .footer-copyright -->*/}
    <div className="footer-btm">
      <p>펜실베니아 치과교정과 치과의원 Pennsylvania Orthodontic Clinic, Guri-si, Kyuounggi-Do</p>
      <div className="footer-credits">
        <p className="footer-copyright">
          <span>© 2022 펜실베니아 치과 All Rights Reserved</span> 
          {/* <a href="https://penn.co.kr/"></a> */}
        </p>
      </div>
    </div>
  </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string
}

Footer.defaultProps = {
  siteTitle: ``
}

export default Footer